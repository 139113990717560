import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import s from "./SliderActions.module.css"
import ationImg from "../../img/action1.png"
import {AXI} from "../axi";
import {NavLink} from "react-router-dom";

interface ItemCatigories {
    id: number;
    img: string;
    description: string | null;
    name: string;
    external_link: string | null;
    external: boolean;
}

const SliderActions = () => {

    const settings = {

        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 7000,
        adaptiveHeight: true,
    };
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])

    useEffect(() => {
        fetch(`${AXI}/api/saleactions`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {
                    setArrCat(data);
                },
            )

    }, [])

    return (
        <Slider {...settings}>
            {arrCat.length > 0 && arrCat.map((el) =>
                <div  key={`${el.id}${el.name}`}>
                    <div className={s.wrapperImg}>
                        <NavLink to={"/actions"}>
                            <img src={el.img} alt={el.name} key={el.id}/>
                        </NavLink>
                    </div>
                </div>

            )}
        </Slider>
    );

}

export default SliderActions