import React, { createContext, useEffect, useMemo, useState } from 'react'

export const Theme = {
  BLACK: 'black',
  WHITE: 'white',
}

export const ThemeContext = createContext()

export const ThemeProvider = ({ children, initialTheme }) => {


  const [theme, setTheme] = useState(initialTheme)

  useEffect(() => {
    setTheme(initialTheme);
  }, [initialTheme]);

  useEffect(() => {
    Object.values(Theme).forEach((themeClass) => {
      document.body.classList.remove(themeClass)
    })
    document.body.classList.add(theme)

    console.log(theme,'theme')
  }, [theme])

  const value = useMemo(() => ({ theme, setTheme }), [theme])
console.log(value,'value',theme,initialTheme)
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
