export const IconHookah =()=>{
return(
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.0003 6.33301C17.0003 6.60915 16.5526 6.83301 16.0003 6.83301C15.448 6.83301 15.0003 6.60915 15.0003 6.33301C15.0003 6.05687 15.448 5.83301 16.0003 5.83301C16.5526 5.83301 17.0003 6.05687 17.0003 6.33301Z" fill="#6A6E81"/>
        <path d="M17.0003 8.33301C17.0003 8.60915 16.5526 8.83301 16.0003 8.83301C15.448 8.83301 15.0003 8.60915 15.0003 8.33301C15.0003 8.05687 15.448 7.83301 16.0003 7.83301C16.5526 7.83301 17.0003 8.05687 17.0003 8.33301Z" fill="#6A6E81"/>
        <path d="M19.0003 7.83301C19.5526 7.83301 20.0003 7.60915 20.0003 7.33301C20.0003 7.05687 19.5526 6.83301 19.0003 6.83301C18.448 6.83301 18.0003 7.05687 18.0003 7.33301C18.0003 7.60915 18.448 7.83301 19.0003 7.83301Z" fill="#6A6E81"/>
        <path d="M14.0003 7.33301C14.0003 7.60915 13.5526 7.83301 13.0003 7.83301C12.448 7.83301 12.0003 7.60915 12.0003 7.33301C12.0003 7.05687 12.448 6.83301 13.0003 6.83301C13.5526 6.83301 14.0003 7.05687 14.0003 7.33301Z" fill="#6A6E81"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.3337 7.70982C29.3337 4.188 23.3641 1.33301 16.0003 1.33301C8.63653 1.33301 2.66699 4.188 2.66699 7.70982C2.66699 8.34044 2.79881 8.9641 3.054 9.54078L4.08667 11.8744C5.02488 13.9946 7.12522 15.362 9.44371 15.362H9.72365L9.61999 16.1221C8.23683 16.5572 7.3954 18.0808 7.90865 19.5473C8.10783 20.1163 8.48678 20.5854 8.9685 20.8997L8.03383 27.754C8.01166 27.8349 8.00033 27.9168 8.00033 27.9997C8.00033 29.4724 11.582 30.6663 16.0003 30.6663C20.4186 30.6663 24.0003 29.4724 24.0003 27.9997C24.0003 27.9168 23.989 27.8349 23.9668 27.754L23.0322 20.8997C23.5139 20.5854 23.8928 20.1163 24.092 19.5473C24.6053 18.0808 23.7638 16.5572 22.3807 16.1221L22.277 15.362H22.496C24.7688 15.362 26.8351 14.0436 27.7928 11.9825L28.9307 9.53353C29.1961 8.9622 29.3337 8.33981 29.3337 7.70982ZM26.3707 6.43448C26.3707 8.54757 21.7277 10.2606 16.0003 10.2606C10.2729 10.2606 5.62996 8.54757 5.62996 6.43448C5.62996 4.3214 10.2729 2.66634 16.0003 2.66634C21.7277 2.66634 26.3707 4.3214 26.3707 6.43448Z" fill="#6A6E81"/>
    </svg>
)
}