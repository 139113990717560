export const IconGER =()=>{
return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1405_9257)">
            <path d="M2.62207 15.4784C4.035 19.2864 7.70043 22.0002 12.0001 22.0002C16.2997 22.0002 19.9652 19.2864 21.3781 15.4784L12.0001 14.6089L2.62207 15.4784Z" fill="#FFDA44"/>
            <path d="M12.0001 2C7.70043 2 4.035 4.71375 2.62207 8.52176L12.0001 9.39129L21.3781 8.52172C19.9652 4.71375 16.2997 2 12.0001 2Z" fill="black"/>
            <path d="M2.62199 8.52197C2.22004 9.60529 2 10.777 2 12.0002C2 13.2234 2.22004 14.3951 2.62199 15.4785H21.378C21.78 14.3951 22 13.2234 22 12.0002C22 10.777 21.78 9.60529 21.378 8.52197H2.62199Z" fill="#D80027"/>
        </g>
        <defs>
            <clipPath id="clip0_1405_9257">
                <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
            </clipPath>
        </defs>
    </svg>

)
}