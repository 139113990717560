// Общая функция для вызова
import {AXI} from "../libs/axi";
import * as React from "react";

export const callService = async (
    endpoint: string,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>,
    tableId: string,
) => {
    try {
        // const tableId = localStorage.getItem('tableId'); // Получаем tableId из localStorage
        //
        // if (!tableId) {
        //     alert('Таблица не выбрана');
        //     return;
        // }

        setDisabled(true); // Блокируем кнопку

        const response = await fetch(`${AXI}/api/orders/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tableId: Number(tableId), // Используем tableId из localStorage
            }),
        });

        const data = await response.json();

        if (response.ok) {
            // Устанавливаем таймер для разблокировки кнопки
            const timestamp = Date.now();
            localStorage.setItem(`disable_${endpoint}_until`, (timestamp + 60000).toString());

            // Таймер для разблокировки
            setTimeout(() => setDisabled(false), 60000);
        } else {
            setDisabled(false);
            throw new Error(data.message);
        }
    } catch (error) {
        console.error(error);
        alert(`Ошибка при вызове ${endpoint}`);
    }
};