
import React, {ReactNode, useEffect, useState} from "react";
import {useGetLocationData} from "../../hooks/locationData";
import ModalFormItem from "../ModalFormItem";
import PreloaderMain from "../PreloaderMain";
import s from './Layout.module.css'
import clsx from "clsx";
import ModalCategories from "../ModalCategories";
import {getRestoranTable} from "../../api/restoran";
import {AXI} from "../../libs/axi";



interface Elements {
    children: ReactNode;
}
interface ModalProps {
    onSave: (tableId: string) => void;
    onCancel: () => void;
}

const TableModal: React.FC<ModalProps> = ({ onSave, onCancel }) => {
    const [inputValue, setInputValue] = useState('');

    const handleSave = () => {
        if (inputValue) {
            onSave(inputValue);
        }
    };


        const handleSaveRods = async () => {
            if (inputValue) {

                const tableRest = await getRestoranTable(inputValue).then((res: any) =>  res.data);

                if(tableRest){
                    onSave(tableRest.id);
                }
            }
        };


    return (
        <div className={s.modal}>
            <div className={s.modalContent}>
                <h2>Введите номер стола</h2>
                <p>Номер находится на табличке qr кода</p>
                <input
                    type="number"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Номер стола"
                />
                <button onClick={onCancel}>Отменить</button>
                <button onClick={AXI.includes("edabirja.ru") ? handleSaveRods  : handleSave}>Сохранить</button>
            </div>
        </div>
    );
};

const Layout = (props:Elements) => {
    const { table, animation } = useGetLocationData();
    const {children}=props
    const { searchText, modal,modalCategories,modalPodbor,modalActions } = useGetLocationData();
    const [tableId, setTableId] = useState("");
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const isAnimation = !!table

    const saveTableId = (id: string) => {
        setTableId(id);
        const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000; // 2 часа
        localStorage.setItem('tableId', id);
        localStorage.setItem('tableIdExpiration', expirationTime.toString());
        setShowModal(false);
    };
    useEffect(() => {

        if(table){
            saveTableId(table)
        }


    }, []);
    useEffect(() => {
        const savedTableId = localStorage.getItem('tableId');
        const expirationTime = localStorage.getItem('tableIdExpiration');
        if(!table){

        if (savedTableId && expirationTime) {
            const now = new Date().getTime();
            if (now < parseInt(expirationTime)) {
                setTableId(savedTableId);
            } else {
                localStorage.removeItem('tableId');
                localStorage.removeItem('tableIdExpiration');
            }
        } else {
            setShowModal(true);
        }
        }

        setLoading(false);
    }, []);



    const cancelModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (!loading && !tableId) {
            setShowModal(true);
        }
    }, [loading, tableId]);


    return (
        <>

            {isAnimation &&
            <PreloaderMain />
            }

            {showModal && <TableModal onSave={saveTableId} onCancel={cancelModal} />}
            <div className={clsx({
                // [s.wrapperLoaders]:isAnimation
            })} style={{pointerEvents:!!modal ? "none" : "auto"}} >
                {children}
            </div>

            {modal && modal.length > 0 && <ModalFormItem  modal={modal}/>}
            {(modalCategories && modalCategories.length > 0 || modalPodbor && modalPodbor.length > 0 || modalActions && modalActions.length > 0 ) && <ModalCategories  modalCategories={modalCategories} modalPodbor={modalPodbor} modalActions={modalActions}/>}
            </>
    )
}

export default Layout