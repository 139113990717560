import React, {FC, useEffect, useRef, useState} from "react";

import eatPng from "../../../img/categoriesIconEat.png"
import drinkPng from "../../../img/categoriesIconDrink.png"
import s from "./Recommendation.module.css"
import ItemOrder from "../../../components/ItemOrder";
import {AXI} from "../../../libs/axi";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    name: string;
}

interface ObjectModal {
    id: number;
    count:number;
}

interface Item {
    active?: number;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const Recommendation:FC <Item>  = ({active=1,handleClickRes,objectModal}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])

    useEffect(() => {
        fetch(`${AXI}/api/items/recommendation`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {
                    setArrCat(data);
                },
            )

    }, [active])

    return (
        arrCat.length > 0 ?
      <div className={s.wrapper}>
        <div className={s.title}>Рекомендуем</div>
        {arrCat.length > 0 && arrCat.map(({id, name, img, price,discount,discountprocent},index) =>
            <ItemOrder
                key={`${id}${name}${index}1`}
                id={id}
                name={name}
                img={img}
                price={price}
                discount={discount}
                discountprocent={discountprocent}
                handleClickRes={handleClickRes}
                objectModal={objectModal}
            />
        )}
    </div> : <></>

    )
}

export default Recommendation