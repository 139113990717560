export const IconPhone =()=>{
return(

    <svg width="393" height="434" viewBox="0 0 393 434" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1323_14412)">
            <g filter="url(#filter0_i_1323_14412)">
                <rect x="36.582" y="0.596191" width="321.322" height="676.796" rx="50.9109" fill="black"/>
            </g>
            <rect x="38.4205" y="2.43464" width="317.645" height="673.119" rx="49.0725" stroke="#373634" strokeWidth="3.67689"/>
            <g filter="url(#filter1_i_1323_14412)">
                <rect x="35.7051" width="323.078" height="677.989" rx="48.6481" fill="black" fillOpacity="0.01"/>
            </g>
            <rect x="35.9879" y="0.282838" width="322.512" height="677.424" rx="48.3652" stroke="#292824" strokeWidth="0.565675"/>
            <g filter="url(#filter2_i_1323_14412)">
                <rect x="36.582" y="0.596191" width="321.322" height="676.796" rx="48.6481" fill="black" fillOpacity="0.01"/>
            </g>
            <rect x="37.1477" y="1.16187" width="320.191" height="675.665" rx="48.0824" stroke="url(#paint0_linear_1323_14412)" strokeWidth="1.13135"/>
            <mask id="mask0_1323_14412" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="47" y="12" width="301" height="654">
                <rect x="47.9688" y="12.8281" width="299.347" height="652.879" rx="35.3572" fill="white"/>
            </mask>
            <g mask="url(#mask0_1323_14412)">
                <path d="M47.8359 54.9715C47.8359 31.6963 66.7042 12.8281 89.9793 12.8281L305.039 12.8281C328.315 12.8281 347.183 31.6964 347.183 54.9715L347.183 623.563C347.183 646.838 328.315 665.707 305.04 665.707H89.9793C66.7042 665.707 47.8359 646.838 47.8359 623.563L47.8359 54.9715Z" fill="#C4C4C4"/>
            </g>
            <rect x="35.7188" y="68.3208" width="4.84548" height="5.48756" fill="#373634" fillOpacity="0.9"/>
            <rect x="353.906" y="68.5942" width="4.84548" height="5.21318" fill="#373634" fillOpacity="0.9"/>
            <rect x="289.299" width="5.11467" height="4.66443" fill="#373634" fillOpacity="0.9"/>
            <g filter="url(#filter3_iiiiii_1323_14412)">
                <rect x="32.4863" y="245.771" width="3.23032" height="52.955" rx="1.13135" fill="#666560"/>
            </g>
            <rect x="32.6985" y="245.983" width="2.80606" height="52.5307" rx="0.919222" stroke="url(#paint1_linear_1323_14412)" strokeWidth="0.424257"/>
            <g filter="url(#filter4_iiiiii_1323_14412)">
                <rect x="32.4863" y="181.018" width="3.23032" height="53.5037" rx="1.13135" fill="#666560"/>
            </g>
            <rect x="32.6985" y="181.23" width="2.80606" height="53.0795" rx="0.919222" stroke="url(#paint2_linear_1323_14412)" strokeWidth="0.424257"/>
            <g filter="url(#filter5_iiiiii_1323_14412)">
                <rect x="32.4863" y="131.08" width="3.23032" height="28.8097" rx="1.13135" fill="#666560"/>
            </g>
            <rect x="32.6985" y="131.292" width="2.80606" height="28.3854" rx="0.919222" stroke="url(#paint3_linear_1323_14412)" strokeWidth="0.424257"/>
            <g filter="url(#filter6_iiiiii_1323_14412)">
                <rect x="358.48" y="198.852" width="3.23032" height="81.2159" rx="1.13135" fill="#666560"/>
            </g>
            <rect x="358.693" y="199.064" width="2.80606" height="80.7917" rx="0.919222" stroke="url(#paint4_linear_1323_14412)" strokeWidth="0.424257"/>
            <rect x="156.299" y="23.0437" width="79.9532" height="22.2302" rx="11.082" fill="#0A0A0A"/>
            <ellipse cx="225.122" cy="34.1588" rx="11.1125" ry="11.1151" fill="#0A0A0A"/>
            <g opacity="0.7">
                <path d="M225.142 40.6095C228.555 40.6095 231.314 37.7911 231.314 34.323C231.314 30.8548 228.555 28.0365 225.142 28.0365C221.73 28.0365 218.971 30.8548 218.971 34.323C218.971 37.7911 221.73 40.6095 225.142 40.6095Z" fill="url(#paint5_radial_1323_14412)"/>
                <path d="M225.142 40.6095C228.555 40.6095 231.314 37.7911 231.314 34.323C231.314 30.8548 228.555 28.0365 225.142 28.0365C221.73 28.0365 218.971 30.8548 218.971 34.323C218.971 37.7911 221.73 40.6095 225.142 40.6095Z" fill="url(#paint6_angular_1323_14412)"/>
                <path d="M225.142 40.6095C228.555 40.6095 231.314 37.7911 231.314 34.323C231.314 30.8548 228.555 28.0365 225.142 28.0365C221.73 28.0365 218.971 30.8548 218.971 34.323C218.971 37.7911 221.73 40.6095 225.142 40.6095Z" fill="url(#paint7_angular_1323_14412)"/>
                <path d="M225.142 40.6095C228.555 40.6095 231.314 37.7911 231.314 34.323C231.314 30.8548 228.555 28.0365 225.142 28.0365C221.73 28.0365 218.971 30.8548 218.971 34.323C218.971 37.7911 221.73 40.6095 225.142 40.6095Z" stroke="#080F21" strokeWidth="0.417668"/>
            </g>
            <line opacity="0.7" x1="225.167" y1="28.2454" x2="225.167" y2="40.4006" stroke="black" strokeWidth="1.04417"/>
            <g filter="url(#filter7_iii_1323_14412)">
                <rect x="174.453" y="6.59277" width="47.2079" height="3.85263" rx="1.92631" fill="#141414"/>
            </g>
            <rect x="174.602" y="6.74124" width="46.9109" height="3.5557" rx="1.77785" stroke="#0E0E0E" strokeWidth="0.296931"/>
        </g>
        <rect y="430" width="393" height="4" fill="url(#paint8_linear_1323_14412)"/>
        <rect y="430" width="393" height="4" fill="url(#paint9_linear_1323_14412)"/>
        <rect y="430" width="393" height="4" fill="url(#paint10_linear_1323_14412)"/>
        <defs>
            <filter id="filter0_i_1323_14412" x="36.582" y="0.596191" width="321.322" height="676.796" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="4.24257" operator="erode" in="SourceAlpha" result="effect1_innerShadow_1323_14412"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.141419"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.133333 0 0 0 0 0.137255 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter1_i_1323_14412" x="35.7051" y="0" width="323.078" height="677.99" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1.41419" operator="erode" in="SourceAlpha" result="effect1_innerShadow_1323_14412"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.141419"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.278431 0 0 0 0 0.27451 0 0 0 0 0.254902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter2_i_1323_14412" x="36.582" y="0.596191" width="321.322" height="676.796" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1.41419" operator="erode" in="SourceAlpha" result="effect1_innerShadow_1323_14412"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.141419"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.686275 0 0 0 0 0.690196 0 0 0 0 0.670588 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter3_iiiiii_1323_14412" x="32.4863" y="245.205" width="4.36182" height="54.0864" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.13135"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.568627 0 0 0 0 0.54902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.41419"/>
                <feGaussianBlur stdDeviation="0.565675"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.572549 0 0 0 0.6 0"/>
                <feBlend mode="normal" in2="effect1_innerShadow_1323_14412" result="effect2_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect2_innerShadow_1323_14412" result="effect3_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect3_innerShadow_1323_14412" result="effect4_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect4_innerShadow_1323_14412" result="effect5_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect5_innerShadow_1323_14412" result="effect6_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter4_iiiiii_1323_14412" x="32.4863" y="180.452" width="4.36182" height="54.635" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.13135"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.568627 0 0 0 0 0.54902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.41419"/>
                <feGaussianBlur stdDeviation="0.565675"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.572549 0 0 0 0.6 0"/>
                <feBlend mode="normal" in2="effect1_innerShadow_1323_14412" result="effect2_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect2_innerShadow_1323_14412" result="effect3_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect3_innerShadow_1323_14412" result="effect4_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect4_innerShadow_1323_14412" result="effect5_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect5_innerShadow_1323_14412" result="effect6_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter5_iiiiii_1323_14412" x="32.4863" y="130.515" width="4.36182" height="29.9412" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.13135"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.568627 0 0 0 0 0.54902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="1.41419"/>
                <feGaussianBlur stdDeviation="0.565675"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.572549 0 0 0 0.6 0"/>
                <feBlend mode="normal" in2="effect1_innerShadow_1323_14412" result="effect2_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect2_innerShadow_1323_14412" result="effect3_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect3_innerShadow_1323_14412" result="effect4_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect4_innerShadow_1323_14412" result="effect5_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect5_innerShadow_1323_14412" result="effect6_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter6_iiiiii_1323_14412" x="357.349" y="198.286" width="4.36182" height="82.3472" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-1.13135"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.568627 0 0 0 0 0.54902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-1.41419"/>
                <feGaussianBlur stdDeviation="0.565675"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.572549 0 0 0 0.6 0"/>
                <feBlend mode="normal" in2="effect1_innerShadow_1323_14412" result="effect2_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect2_innerShadow_1323_14412" result="effect3_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2.2627"/>
                <feGaussianBlur stdDeviation="0.282838"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.152941 0 0 0 0 0.133333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect3_innerShadow_1323_14412" result="effect4_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect4_innerShadow_1323_14412" result="effect5_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.848513"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.741176 0 0 0 0 0.721569 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect5_innerShadow_1323_14412" result="effect6_innerShadow_1323_14412"/>
            </filter>
            <filter id="filter7_iii_1323_14412" x="174.453" y="6.59277" width="47.207" height="3.85254" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="0.296931" operator="erode" in="SourceAlpha" result="effect1_innerShadow_1323_14412"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.742327"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="0.890792"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect1_innerShadow_1323_14412" result="effect2_innerShadow_1323_14412"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-0.890792"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect2_innerShadow_1323_14412" result="effect3_innerShadow_1323_14412"/>
            </filter>
            <linearGradient id="paint0_linear_1323_14412" x1="197.243" y1="0.596191" x2="197.243" y2="677.392" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B5B5B5"/>
                <stop offset="1" stopColor="#CDCDC5"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1323_14412" x1="30.7243" y1="268.822" x2="37.4786" y2="268.822" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7F7E79"/>
                <stop offset="0.25" stopColor="#5E5D58"/>
                <stop offset="0.5" stopColor="#55544F"/>
                <stop offset="0.682292" stopColor="#0F0E0A"/>
                <stop offset="0.937329" stopColor="#71706B"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1323_14412" x1="30.7243" y1="204.307" x2="37.4786" y2="204.307" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7F7E79"/>
                <stop offset="0.25" stopColor="#5E5D58"/>
                <stop offset="0.5" stopColor="#55544F"/>
                <stop offset="0.682292" stopColor="#0F0E0A"/>
                <stop offset="0.937329" stopColor="#71706B"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1323_14412" x1="30.7243" y1="143.621" x2="37.4786" y2="143.621" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7F7E79"/>
                <stop offset="0.25" stopColor="#5E5D58"/>
                <stop offset="0.5" stopColor="#55544F"/>
                <stop offset="0.682292" stopColor="#0F0E0A"/>
                <stop offset="0.937329" stopColor="#71706B"/>
            </linearGradient>
            <linearGradient id="paint4_linear_1323_14412" x1="356.718" y1="234.205" x2="363.473" y2="234.205" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7F7E79"/>
                <stop offset="0.234375" stopColor="#0F0E0A"/>
                <stop offset="0.5" stopColor="#55544F"/>
                <stop offset="0.71875" stopColor="#5E5D58"/>
                <stop offset="0.937329" stopColor="#71706B"/>
            </linearGradient>
            <radialGradient id="paint5_radial_1323_14412" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(225.142 34.323) rotate(90) scale(6.07763 5.96278)">
                <stop offset="0.213542"/>
                <stop offset="0.270833" stopColor="#081A32"/>
                <stop offset="0.395833" stopColor="#1F3A58"/>
                <stop offset="0.515625" stopColor="#375F90"/>
                <stop offset="0.713542" stopColor="#274871"/>
                <stop offset="0.916667" stopColor="#0D1528"/>
            </radialGradient>
            <radialGradient id="paint6_angular_1323_14412" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(225.142 34.323) rotate(90) scale(6.07763 5.96278)">
                <stop offset="0.411458" stopOpacity="0"/>
                <stop offset="0.510417"/>
                <stop offset="0.817708" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint7_angular_1323_14412" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(225.142 34.323) rotate(90) scale(6.07763 5.96278)">
                <stop/>
                <stop offset="0.166667" stopOpacity="0"/>
                <stop offset="0.885417" stopOpacity="0"/>
            </radialGradient>
            <linearGradient id="paint8_linear_1323_14412" x1="0" y1="432" x2="393" y2="432" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0"/>
                <stop offset="0.5" stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint9_linear_1323_14412" x1="0" y1="432" x2="393" y2="432" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0"/>
                <stop offset="0.5" stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint10_linear_1323_14412" x1="0" y1="432" x2="393" y2="432" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0"/>
                <stop offset="0.5" stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <clipPath id="clip0_1323_14412">
                <rect width="329.439" height="430" fill="white" transform="translate(32.4883)"/>
            </clipPath>
        </defs>
    </svg>


)
}