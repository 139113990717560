import axios from 'axios'
// import { getToken } from 'utils/getToken'
import {AXI} from "../libs/axi";
export const request = axios.create({
    baseURL: AXI,
    headers: {
        // accept: 'application/json',
        // ...(getToken ? { authorization: `Basic ${getToken}` } : {}),
        'Content-Type': 'application/json',
    },
})

request.interceptors.response.use(
    (response) => {
        if (response && response.data && response.data.error) {
            console.warn({
                status: response.status,
                url: response.config.url,
                data: response.data,
            })
        }
        return response
    },
    (error) => {
        // whatever you want to do with the error
        console.warn(error)
        throw error
    }
)