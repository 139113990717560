import React, {useEffect, useState} from "react";
import clsx from "clsx";
import s from "./TabsCat.module.css";
import Icon from "../Icon";


interface Tabs {
    activeDrink: number,
    setActiveDrink: React.Dispatch<React.SetStateAction<number>>,

}

export const TabsCat = (props: Tabs) => {
    const {activeDrink, setActiveDrink} = props
    return (

        <div className={s.wrapperActiveButtons}>
            <div className={clsx(s.eat, s.but, {
                [s.activeEat]: activeDrink === 1
            })}
                 onClick={() => setActiveDrink(1)}>
                <div className={s.iconActiveButtons}>
                    <Icon.Glass/>
                </div>
                <span>Поесть</span>
            </div>
            <div className={clsx(s.eat, s.but, {
                [s.activeEat]: activeDrink === 2
            })} onClick={() => setActiveDrink(2)}>
                <div className={s.iconActiveButtons}>
                    <Icon.Martini/>
                </div>
                <span>Выпить</span>
            </div>

        </div>
    )
}

