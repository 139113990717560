import { request } from '../utils/request'
import axios from 'axios'
import {AXI} from "../libs/axi";


export async function getRestoranMini() {
    return request.get(`/api/restoran/mini`)
}

// export async function getOrderCallpay(id) {
//     return request.post(`/api/orders/callpay`,{
//         tableId:id
//     })
// }

export async function getRestoranInfoIP() {
    return request.get(`/api/payments/info`)
}

export async function getRestoranTable(id){
    return request.get(`/api/restoran/table?table=${id}`)
}

