import s from "./RecomendationItem.module.css"

import React, {FC, useEffect, useRef, useState} from "react";
import ItemOrder from "../../ItemOrder";
import {AXI} from "../../../libs/axi";
interface Childse{
    id:number
    name:string
    basicprice:number
}

interface Group{
    id:number,
    maxAmount:number,
    childModifiers:Childse[]
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    description: string | null;
    discountprocent: number | null;
    groupModifiers?: Group[]
    name: string;
}
interface ObjectModal {
    id: number;
    count:number;
}
interface Modal {
    id?: number | null;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const RecomendationItem: FC<Modal> = ({id:idItem ,handleClickRes,objectModal}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])

    useEffect(() => {
        if(idItem) {
            fetch(`${AXI}/api/items/specialcatitems?id=${idItem}&page=1&count=6`)
                .then(res => res.json())
                .then((data) => {
                        setArrCat(data["items"]);
                    },
                )
        }
    }, [idItem])

    return (
        arrCat.length > 0 ?
            <div className={s.wrapper}>
                <div className={s.title}>Рекомендуем</div>
                {arrCat.length > 0 && arrCat.map(({id, name, img, price,discount,discountprocent,groupModifiers},index) =>
                    <ItemOrder
                        key={`${id}${name}${index}1`}
                        id={id}
                        name={name}
                        img={img}
                        price={price}
                        discount={discount}
                        discountprocent={discountprocent}
                        handleClickRes={handleClickRes}
                        groupModifiers={groupModifiers}
                        objectModal={objectModal}
                        small={true}
                    />
                )}
            </div> : <div />
    )
}

export default RecomendationItem