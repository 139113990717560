export const IconENG =()=>{
return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1405_9251)">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
            <path d="M11.5655 11.9999H22.0002C22.0002 11.0973 21.8799 10.223 21.6557 9.39121H11.5655V11.9999ZM11.5655 6.78254H20.5325C19.921 5.78537 19.1401 4.90256 18.2251 4.17383H11.5655V6.78254ZM12.0002 21.9999C14.3537 21.9999 16.5169 21.1865 18.2251 19.826H5.77539C7.48359 21.1865 9.64676 21.9999 12.0002 21.9999ZM3.46797 17.2173H20.5325C21.0298 16.406 21.4081 15.5274 21.6557 14.6086H2.34473C2.59235 15.5274 2.97063 16.406 3.46797 17.2173Z" fill="#D80027"/>
            <path d="M6.63219 3.56164H7.54348L6.69582 4.17746L7.01961 5.17391L6.17199 4.55809L5.32437 5.17391L5.60406 4.31309C4.85635 4.93596 4.20303 5.66409 3.66453 6.47469H3.95652L3.41695 6.86668C3.33282 7.0071 3.25214 7.14956 3.175 7.29395L3.43266 8.08695L2.95195 7.7377C2.832 7.99171 2.72289 8.25071 2.62492 8.51398L2.90879 9.38773H3.95652L3.10887 10.0036L3.43266 11L2.58504 10.3842L2.0773 10.7531C2.02582 11.1667 2.00001 11.5832 2 12H12V2C10.0245 2 8.18301 2.57305 6.63219 3.56164ZM7.01961 11L6.17199 10.3842L5.32437 11L5.64816 10.0036L4.80051 9.38773H5.84824L6.17199 8.39129L6.49574 9.38773H7.54348L6.69582 10.0036L7.01961 11ZM6.69582 7.09051L7.01961 8.08695L6.17199 7.47113L5.32437 8.08695L5.64816 7.09051L4.80051 6.47469H5.84824L6.17199 5.47824L6.49574 6.47469H7.54348L6.69582 7.09051ZM10.6066 11L9.75895 10.3842L8.91133 11L9.23512 10.0036L8.38746 9.38773H9.4352L9.75895 8.39129L10.0827 9.38773H11.1304L10.2828 10.0036L10.6066 11ZM10.2828 7.09051L10.6066 8.08695L9.75895 7.47113L8.91133 8.08695L9.23512 7.09051L8.38746 6.47469H9.4352L9.75895 5.47824L10.0827 6.47469H11.1304L10.2828 7.09051ZM10.2828 4.17746L10.6066 5.17391L9.75895 4.55809L8.91133 5.17391L9.23512 4.17746L8.38746 3.56164H9.4352L9.75895 2.5652L10.0827 3.56164H11.1304L10.2828 4.17746Z" fill="#0052B4"/>
        </g>
        <defs>
            <clipPath id="clip0_1405_9251">
                <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
            </clipPath>
        </defs>
    </svg>

)
}