
// export const AXI = "http://37.143.12.146:4000"
// export const AXI = "https://edabirja.ru"
// export const AXI = "http://185.87.192.175:4000"
// export const AXI = "https://loft.edabirja.ru"

// sushsam.edabirja.ru
export let AXI:string;
const baseURL = window.location.origin;

if (baseURL.includes("http://localhost:3000") || baseURL.includes("http://localhost:3001")) {
    AXI = "http://185.87.192.175:4000";
} else {
    AXI = baseURL.replace(/(https?:\/\/[^/]+).*/, "$1");
}