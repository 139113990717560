import { useEffect, useState } from 'react';

interface ObjectModal {
    id: number;
    modification?:number[]
    count: number;
}

const arraysEqual = (arr1: ObjectModal[], arr2: ObjectModal[]) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}

const useObjectModal = (searchText: string,arrObject?:ObjectModal[] | undefined ) => {
    const [objectModal, setObjectModal] = useState<ObjectModal[]>(() => {
        const savedData = localStorage.getItem('objectModal');
        return savedData ? JSON.parse(savedData) : [];
    });

    useEffect(()=>{
        if(arrObject){
            setObjectModal(arrObject)
        }
    },[arrObject])

    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                if (Array.isArray(parsedData) && !arraysEqual(objectModal, parsedData)) {
                    setObjectModal(parsedData);
                    console.log('objectModal updated from localStorage.');
                }
            } catch (error) {
                console.error('Error parsing objectModal from localStorage:', error);
            }
        }
    }, [searchText]);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);

    const addObjectModalItem = (id: number, count: number = 1) => {
        setObjectModal(prevState => {
            const itemExists = prevState.some(item => item.id === id);
            if (itemExists) {
                return prevState.map(item => item.id === id ? { ...item, count } : item)
                    .filter(item => item.count > 0);
            } else {
                return [...prevState, { id, count }];
            }
        });
    };

    const clearModalItems = () => {
        setObjectModal([]);
    };

    return { objectModal, addObjectModalItem,clearModalItems };
};

export default useObjectModal;
