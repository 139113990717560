import React, {useState} from "react";

import clsx from "clsx";
import s from "./CheckBox.module.css";

type CheckboxInputProps = {
  name: string;
  defaultValue?: boolean;
  checked?: boolean;
  inputClass?: string;
  label?: string;
  labelComponent?: React.ReactNode;
  labelClass?: string;
  spanClass?: string;
  wrapperClass?: string;
  [key: string]: any;
  onChange: (name: string, checked: boolean) => void;
};

const CheckboxInput = (props: CheckboxInputProps) => {
  const {
    name,
    spanClass,
    labelClass,
    wrapperClass,
    inputClass,
    checked,
    label,
    labelComponent,
    defaultValue = false, // добавляем defaultValue по умолчанию
    onChange,
  } = props;

  // const [checked, setChecked] = useState(defaultValue); // используем defaultValue в качестве начального состояния

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    // setChecked(isChecked);
    onChange(name, isChecked);
  };

  return (
      <div className={clsx(wrapperClass, s.wrapper)}>
        <label htmlFor={name} className={clsx(s.label, labelClass)}>
          <input
              id={name}
              type="checkbox"
              onChange={handleChange}
              checked={checked}
              className={clsx(s.input, inputClass)}
          />
          <span className={clsx(spanClass, s.checkbox_span)}/>
          <span className={s.label_title}>{label || labelComponent}</span>
        </label>
      </div>
  );
}
export default CheckboxInput;
