import s from './SliderItem.module.css';
import Slider from "react-slick";
import React, {FC, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import Icon from "../../Icon";

interface SliderItemFc {
    setIsClose:React.Dispatch<React.SetStateAction<boolean>>
}

const SliderItem:FC<SliderItemFc> = ({setIsClose}) => {
    const sliderRef = useRef<Slider | null>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false); // Контроль за воспроизведением видео

    useEffect(() => {
        // Даем 6 секунд до начала воспроизведения первого видео
        const timer = setTimeout(() => {
            const firstVideoElement = document.querySelector(`#video-0`) as HTMLVideoElement | null;
            if (firstVideoElement) {
                firstVideoElement.play();
                setIsPlaying(true); // Видео воспроизводится
            }
        }, 6000);

        return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
    }, []);

    const videoUrls = [
        "/img/video/slide-1.mp4",
        "/img/video/slide-2.mp4",
        "/img/video/slide-3.mp4",
        "/img/video/slide-4.mp4",
        "/img/video/slide-5.mp4",
    ];

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        beforeChange: (current: number, next: number) => {
            setActiveSlideIndex(next); // Обновляем индекс активного слайда перед изменением
            setIsPlaying(false); // Сбрасываем флаг воспроизведения
        },
        afterChange: (index: number) => {
            const videoElement = document.querySelector(`#video-${index}`) as HTMLVideoElement | null;
            if (videoElement) {
                videoElement.play().then(() => setIsPlaying(true)).catch(() => setIsPlaying(false)); // Стартуем видео только после загрузки
            }
        },
        customPaging: (i: number) => (
            <div className={s.customDot}> {/* Кастомная разметка для точек */}
                <span>{i + 1}</span> {/* Можно заменить на иконки или изображения */}
            </div>
        )
    };


    const handleVideoEnded = (index: number) => {
        if (sliderRef.current && isPlaying) {
            const nextIndex = index + 1;
            if (nextIndex < videoUrls.length) {
                sliderRef.current.slickGoTo(nextIndex);
            }
        }
    };

    return (
        <div className={clsx(s.slide, {
            [s[`slide-${activeSlideIndex}`]]: activeSlideIndex >= 0
        })}>
            <button
                // to={`/categories/${el.id}`}
                onClick={()=> setIsClose(true)}
                className={s.showMoreButton} >
                Пропустить
                <Icon.ArrowMore/>
            </button>
            <div className={s.wrap}>
                <div className={s.icon}>
                    <img src={'/img/Phone.png'} />
                </div>
                <div className={s.item}>
                    <Slider ref={sliderRef} {...settings}>
                        {videoUrls.map((url, index) => (
                            <div key={index}>
                                <video
                                    id={`video-${index}`}
                                    src={url}
                                    width="100%"
                                    height="auto"
                                    muted
                                    playsInline
                                    controls={false}
                                    preload="auto"
                                    onEnded={() => handleVideoEnded(index)} // Переход на следующий слайд после завершения видео
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className={s.line} />
                {activeSlideIndex === 0 && <div className={s.text}>
                    Специальные подборки <br/>и рекомендации
                </div>}
                {activeSlideIndex === 1 && <div className={s.text}>
                    Заказ не дожидаясь <br/> официанта
                </div>}
                {activeSlideIndex === 2 &&<div className={s.text}>
                    Подробная информация<br/>о любой позиции
                </div>}
                {activeSlideIndex === 3 &&<div className={s.text}>
                    Удобная навигация<br/>и поиск
                </div>}
                {activeSlideIndex === 4 && <div className={s.text}>
                    Акции и специальные<br/>предложения
                </div>}

                <div className={s.dots}>
                    {videoUrls.map((_, index) => (
                        <button
                            key={index}
                            className={clsx(s.dot, {
                                [s.active]: index === activeSlideIndex,
                            })}
                            onClick={() => sliderRef.current?.slickGoTo(index)}
                        />
                    ))}
            </div>
        </div>
        </div>
    );
};

export default SliderItem;
