export const IconRU =()=>{
return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1401_9557)">
            <path d="M12 22C17.5223 22 22 17.5223 22 12C22 6.47773 17.5223 2 12 2C6.47773 2 2 6.47773 2 12C2 17.5223 6.47773 22 12 22Z" fill="white"/>
            <path d="M21.3777 15.4765C22.2074 13.2322 22.2074 10.7653 21.3777 8.521H2.62227C1.79258 10.7653 1.79258 13.2322 2.62227 15.4765L12 16.3503L21.3777 15.4765Z" fill="#0052B4"/>
            <path d="M11.9998 22C16.2998 22 19.9662 19.2891 21.3775 15.4766H2.62207C4.03496 19.2891 7.69981 22 11.9998 22Z" fill="#D80027"/>
        </g>
        <defs>
            <clipPath id="clip0_1401_9557">
                <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
            </clipPath>
        </defs>
    </svg>
)
}